import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { momenttz } from '@international/mastodon-i18n';
import { dateWithTimezone } from '../lib/formatting';

const licenseText = (number, state) => `${number} (${state})`;

const getDriverLicenses = (report, isCurrent) => {
  const { motorVehicleReport, candidate } = report;

  if (motorVehicleReport && motorVehicleReport.licenseReports) {
    const licenses = motorVehicleReport.licenseReports
      .filter(licenseReport => licenseReport.currentLicense === isCurrent)
      .map(licenseReport =>
        licenseText(licenseReport.number, licenseReport.state),
      );

    if (licenses.length > 0) {
      return licenses.join(', ');
    }
  } else if (
    isCurrent &&
    candidate.driverLicenseNumber &&
    candidate.driverLicenseState
  ) {
    return licenseText(
      candidate.driverLicenseNumber,
      candidate.driverLicenseState,
    );
  } else if (
    !isCurrent &&
    candidate.previousDriverLicenseNumber &&
    candidate.previousDriverLicenseState
  ) {
    return licenseText(
      candidate.previousDriverLicenseNumber,
      candidate.previousDriverLicenseState,
    );
  }
  return '-';
};

const USProfile = ({ report, pdf, segmentationEnabled }) => (
  <div>
    <div className='row mb-md-4'>
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.firstName' />
        </span>
        <br />
        {report.candidate.firstName}
      </div>
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.middleName' />
        </span>
        <br />
        {report.candidate.middleName || '-'}
      </div>
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.lastName' />
        </span>
        <br />
        {report.candidate.lastName}
      </div>
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.dob' />
        </span>
        <br />
        {momenttz(report.candidate.dob).format('MMM DD, [XXXX]')}
      </div>
    </div>

    <div className='row mb-md-4'>
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.phone' />
        </span>
        <br />
        {report.candidate.phone}
      </div>
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.zipcode' />
        </span>
        <br />
        {report.candidate.zipcode}
      </div>
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.email' />
        </span>
        <br />
        <div className='text-wrap' title={report.candidate.email}>
          {report.candidate.email}
        </div>
      </div>
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.ssn' />
        </span>
        <br />
        {report.candidate.ssn || '-'}
      </div>
    </div>

    <div className='row mb-md-4'>
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.driverLicenseNumber' />
        </span>
        <br />
        <span>{getDriverLicenses(report, true)}</span>
      </div>
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.previousDriverLicenses' />
        </span>
        <br />
        <span>{getDriverLicenses(report, false)}</span>
      </div>

      {report.candidate.motherMaidenName && (
        <div className='col-md-3 mb-3 mb-md-0'>
          <span className='text-very-muted'>
            <T value='labels.motherMaidenName' />
          </span>
          <br />
          {report.candidate.motherMaidenName}
        </div>
      )}
      {report.geos && report.geos.length > 0 && (
        <div className='col-md-3 mb-3 mb-md-0'>
          <span className='text-very-muted'>
            {segmentationEnabled ? (
              <T value='labels.complianceGeos' />
            ) : (
              <T value='labels.geos' />
            )}
          </span>
          <br />
          {report.geos
            .map(geo => {
              const domesticGeo = !geo.country || geo.country === 'US';
              if (segmentationEnabled && domesticGeo) {
                return [geo.state, geo.city].filter(x => x).join(' - ');
              }
              return geo.name;
            })
            .join(', ')}
        </div>
      )}
      {report.workLocations && report.workLocations.length > 0 && (
        <div className='col-md-3 mb-3 mb-md-0'>
          <span className='text-very-muted'>
            <T value='labels.workLocations' />
          </span>
          <br />
          {report.workLocations
            .map(({ country, state, city }) => {
              return [country, state, city].filter(x => x).join(' - ');
            })
            .join(', ')}
        </div>
      )}
      {report.candidate.customId && (
        <div className='col-md-3 mb-3 mb-md-0'>
          <span className='text-very-muted'>
            <T value='labels.customId' />
          </span>
          <br />
          {report.candidate.customId}
        </div>
      )}
    </div>

    <div className='row mb-md-4'>
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.createdAt' />
        </span>
        <br />
        {dateWithTimezone(report, 'createdAt', pdf)}
      </div>
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.completedAt' />
        </span>
        <br />
        {report.completedAt
          ? dateWithTimezone(report, 'completedAt', pdf)
          : '-'}
      </div>
      {report.revisedAt && (
        <div className='col-md-3 mb-3 mb-md-0'>
          <span className='text-very-muted'>
            <T value='labels.revisedAt' />
          </span>
          <br />
          {dateWithTimezone(report, 'revisedAt', pdf)}
        </div>
      )}
    </div>
  </div>
);

USProfile.propTypes = {
  pdf: PropTypes.bool,
  report: PropTypes.object.isRequired,
  segmentationEnabled: PropTypes.bool,
};

USProfile.defaultProps = {
  pdf: false,
  segmentationEnabled: false,
};

export default USProfile;
