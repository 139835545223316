import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import '../scss/Footer.scss';
import { trackAnalyticsEvent as trackAnalyticsEventAction } from '../actions';
import { ANALYTICS_EVENTS } from '../lib/analytics';

const Footer = ({ international = false, trackAnalyticsEvent }) => {
  return (
    <>
      <footer className='footer text-center' data-testid='privacy-policy-link'>
        {i18n.renderHTML('components.Footer.privacyPolicyLink')}
      </footer>
      <footer className='footer text-center'>
        <div className='checkr-logo'>
          <img
            src='https://assets.checkr.com/logo-aqua-2022.svg'
            style={{ height: '34px', width: '145px' }}
            alt={i18n.getStr('components.Footer.checkrLogo')}
          />
        </div>
        <div>
          <T value='address' dangerousHTML />
        </div>
        <div>
          <a
            data-testid='help-center-footer-link'
            href={i18n.getStr('components.Footer.checkrHelpCenterUrl')}
            target='_blank'
            rel='noopener noreferrer'
            onClick={() =>
              trackAnalyticsEvent(ANALYTICS_EVENTS.HELP_CENTER_VIEWED)
            }
          >
            <T value='components.Footer.helpCenter' />
          </a>
          &nbsp;
          <a
            href={i18n.getStr('components.Footer.checkrPrivacyPolicyUrl')}
            target='_blank'
            rel='noopener noreferrer'
          >
            <T value='components.Footer.privacyPolicy' />
          </a>
          &nbsp;
          <a
            href={i18n.getStr('components.Footer.checkrTermsOfServiceUrl')}
            target='_blank'
            rel='noopener noreferrer'
          >
            <T value='components.Footer.termsOfService' />
          </a>
        </div>
        <div className='reCaptcha'>
          <span>
            <T value='components.Footer.protectedRecaptcha' />
          </span>
          &nbsp;
          <a
            href={`https://www.google.com/intl/${i18n.getLocale()}/policies/privacy/`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <T value='components.Footer.recaptchaPrivacy' />
          </a>
          &nbsp;
          <a
            href={`https://www.google.com/intl/${i18n.getLocale()}/policies/terms/`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <T value='components.Footer.recaptchaTerms' />
          </a>
        </div>
      </footer>
    </>
  );
};

Footer.propTypes = {
  international: PropTypes.bool.isRequired,
  trackAnalyticsEvent: PropTypes.func,
};

Footer.defaultProps = {
  trackAnalyticsEvent: () => {},
};

export default i18n.renderTranslation()(
  connect(null, {
    trackAnalyticsEvent: trackAnalyticsEventAction,
  })(Footer),
);
