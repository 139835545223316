export enum ReportView {
  CANADA_VIEW = 'canada',
  CRIMINAL_VIEW = 'criminal',
  CLEARINGHOUSE_VIEW = 'clearinghouse',
  DRUG_ALCOHOL_CLEARINGHOUSE_VIEW = 'drug_alcohol_clearinghouse',
  HEALTH_VIEW = 'health',
  CREDIT_VIEW = 'credit',
  SELF_DISCLOSURE_VIEW = 'self_disclosure',
  INTERNATIONAL_VIEW = 'international',
  PLV_VIEW = 'professional_license_verification',
}

const TOGGLE_SCREENINGS = [
  'creditReport',
  'drugScreening',
  'occupationalHealthScreening',
  'clearinghouseSearch',
  'professionalLicenseVerifications',
  'drugAlcoholClearinghouse',
];

const {
  CANADA_VIEW,
  CRIMINAL_VIEW,
  CLEARINGHOUSE_VIEW,
  DRUG_ALCOHOL_CLEARINGHOUSE_VIEW,
  HEALTH_VIEW,
  CREDIT_VIEW,
  SELF_DISCLOSURE_VIEW,
  INTERNATIONAL_VIEW,
  PLV_VIEW,
} = ReportView;

const CLEAR_STATUS = 'clear';
const CONSIDER_STATUS = 'consider';
const DISPUTE_STATUS = 'dispute';
const SUSPENDED_STATUS = 'suspended';
const PENDING_STATUS = 'pending';
const CANCELED_STATUS = 'canceled';
const COMPLETE_STATUS = 'complete';

const COMPLETE_STATUSES = [CLEAR_STATUS, CONSIDER_STATUS, COMPLETE_STATUS];
const PROCESSING_STATUSES = [PENDING_STATUS, SUSPENDED_STATUS];

const SEARCHES = {
  'SSN Trace': 'ssnTrace',
  'Civil Search': 'evictionSearch',
  'Sex Offender Search': 'sexOffenderSearch',
  'Global Watchlist Search': 'terroristWatchlistSearch',
  'Healthcare Sanctions Searches': 'healthcareSanctionsSearches',
  'International Search': 'internationalCriminalSearches',
  'Arrest Search': 'arrestSearch',
  'National Search': 'nationalCriminalSearch',
  'State Searches': 'stateCriminalSearches',
  'Pointer State Searches': 'pointerStateCriminalSearches',
  'Federal Search': 'federalCriminalSearch',
  'Federal District Searches': 'federalDistrictCriminalSearches',
  'Federal District Civil Searches': 'federalDistrictCivilSearches',
  'Federal Civil Search': 'federalCivilSearch',
  'County Searches': 'countyCriminalSearches',
  'County Civil Searches': 'countyCivilSearches',
  'Municipal Searches': 'municipalCriminalSearches',
  'Child Abuse Searches': 'childAbuseSearches',
  'Motor Vehicle Report': 'motorVehicleReport',
  'Employment Verification': 'employmentVerification',
  'Education Verification': 'educationVerification',
  'Personal Reference Verification': 'personalReferenceVerifications',
  'Professional Reference Verification': 'professionalReferenceVerifications',
  FACIS: 'facisSearch',
  'Clearinghouse Search': 'clearinghouseSearch',
  'Professional License Verification': 'professionalLicenseVerifications',
  'International Adverse Media Searches': 'internationalAdverseMediaSearches',
  'International Criminal Searches': 'internationalCriminalSearchesV2',
  'International Canada BC Enhanced Criminal Search':
    'internationalCanadaBcEnhancedCriminalSearch',
  'International Global Watchlist': 'internationalGlobalWatchlistSearch',
  'International Education Verification': 'internationalEducationVerification',
  'International Employment Verification':
    'internationalEmploymentVerification',
  'International Identity Document Validation':
    'internationalIdentityDocumentValidation',
  'International Motor Vehicle Report': 'internationalMotorVehicleReport',
  'International Right To Work': 'internationalRightToWork',
  'International Credit Report': 'internationalCreditReport',
};

const UPDATE_PERSONAL_INFO_DISABLED_ACCOUNT_URIS = [
  'uber',
  'uberblack',
  'uber_eats',
];

export {
  CANADA_VIEW,
  CANCELED_STATUS,
  CLEAR_STATUS,
  CLEARINGHOUSE_VIEW,
  COMPLETE_STATUS,
  COMPLETE_STATUSES,
  CONSIDER_STATUS,
  CREDIT_VIEW,
  CRIMINAL_VIEW,
  DISPUTE_STATUS,
  DRUG_ALCOHOL_CLEARINGHOUSE_VIEW,
  HEALTH_VIEW,
  PENDING_STATUS,
  PROCESSING_STATUSES,
  SUSPENDED_STATUS,
  SEARCHES,
  SELF_DISCLOSURE_VIEW,
  INTERNATIONAL_VIEW,
  PLV_VIEW,
  UPDATE_PERSONAL_INFO_DISABLED_ACCOUNT_URIS,
  TOGGLE_SCREENINGS,
};
