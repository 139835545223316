import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { SSNTraceObject } from 'types/report';
import Panel from '../../../Panels';

interface SSNTraceProps {
  search: SSNTraceObject;
}

const SSNTrace = ({ search }: SSNTraceProps) =>
  !!search &&
  search.status !== 'canceled' && (
    <Panel title={i18n.getStr('components.ssnTrace')} status={search.status} />
  );

SSNTrace.propTypes = {
  search: PropTypes.object,
};

SSNTrace.defaultProps = {
  search: {},
};

export default i18n.renderTranslation()(SSNTrace);
