import React, { ReactNode } from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import { Type, TypeParams } from './Type';

interface StyleableContainerProps {
  type?: Type;
  children?: ReactNode;
  style?: React.CSSProperties;
  styledTopBorder?: boolean;
}

const defaultStyle = {
  display: 'flex',
  padding: '24px 16px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '24px',
  alignSelf: 'stretch',
  background: colors.uiGray0,
  borderRight: 'hidden',
  borderLeft: 'hidden',
  borderBottom: 'hidden',
} as React.CSSProperties;

export const StyleableContainer: React.FC<StyleableContainerProps> = ({
  type,
  children,
  style = { ...defaultStyle },
  styledTopBorder = false,
}) => {
  if (styledTopBorder) {
    style.borderRadius = '8px';
    style.borderWidth = '4px';
    style.borderTopStyle = 'solid';
    style.borderTopColor = TypeParams[type || Type.Default].color;
  }

  return <M.Container style={style}>{children}</M.Container>;
};
