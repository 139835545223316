import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { i18n } from '@international/mastodon-i18n';

import { useFlagr } from 'api/flagr';
import { FLAGR_KEYS } from '../../constants';
import { addCustomIncident, trackAnalyticsEvent } from '../../actions';
import MainContainer from '../MainContainer';
import { Navigation } from './Navigation';
import ReportContainer from '../Reports/Report/Report';
import { Report as ReportInterface } from '../../types/report';
import { ANALYTICS_EVENTS } from '../../lib/analytics';
import { generateCustomIncidentsContentKey } from '../Reports/Report/lib/incidents';

import './ReportPage.scss';

interface ReportPageProps {
  report: ReportInterface;
  isLoading: boolean;
  international: boolean;
  expungements?: Array<any>;
}

const buildUseFlagrParamentersForReport = (
  flag: string,
  report: ReportInterface,
) => ({
  context: {
    locationHost: window.location.host,
    candidateEmail: report?.candidate?.email,
  },
  id: flag,
  entity: { id: report?.candidate?.email, type: 'candidateEmail' },
  fallbackVariant: 'off',
});

export const ReportPage: React.FC<ReportPageProps> = ({
  report,
  isLoading,
  international,
  expungements = [],
}) => {
  const {
    flag: { variantKey: newSsnResolutionFlow },
  } = useFlagr(
    buildUseFlagrParamentersForReport(FLAGR_KEYS.newSsnResolutionFlow, report),
  );
  const isNewSsnResolutionFlow = newSsnResolutionFlow === 'on';

  const {
    flag: { variantAttachment: countyVariantAttachment },
    isLoading: countyFlagIsLoading,
  } = useFlagr(
    buildUseFlagrParamentersForReport(
      FLAGR_KEYS.showCountyDelayMessage,
      report,
    ),
  );

  const expungement = expungements.find(({ reportId: id }) => id === report.id);

  const dispatch = useDispatch();
  const reportId = report.id;
  useEffect(() => {
    if (!isLoading && report) {
      dispatch(trackAnalyticsEvent(ANALYTICS_EVENTS.REPORT_VIEWED));
    }
  }, [isLoading, dispatch, report]);
  useEffect(() => {
    const customIncidentContextChecks =
      generateCustomIncidentsContentKey(report);

    const customIncidentContentKeys = customIncidentContextChecks
      .filter(({ context: reportContext }) => {
        return countyVariantAttachment?.counties?.some(
          ({ county, state }: { county: string; state: string }) => {
            return (
              county.toLowerCase() ===
                (reportContext as { county: string }).county &&
              state.toLowerCase() === (reportContext as { state: string }).state
            );
          },
        );
      })
      .map(({ contentKey }) => contentKey);

    if (reportId && customIncidentContentKeys.length > 0) {
      dispatch(addCustomIncident(customIncidentContentKeys));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, countyFlagIsLoading, countyVariantAttachment]);

  if (isLoading) return null;

  return (
    <div>
      <MainContainer
        className={`report__wrapper${
          isNewSsnResolutionFlow ? ' report__wrapper--new-flow' : ''
        }`}
        header={
          <Navigation
            text={i18n.getStr('components.Report.homeButton')}
            isInternational={international}
            className={isNewSsnResolutionFlow ? '' : '--prev-view'}
          />
        }
      >
        {report?.account?.name && (
          <div
            data-testid='report-title'
            className={`report__header${
              isNewSsnResolutionFlow ? ' report__header--status' : ''
            }`}
          >
            <h1
              className={`report--account-name${
                isNewSsnResolutionFlow ? '' : ' --prev-view'
              }`}
            >
              {report?.brandingName || report?.account?.name}
            </h1>
            {report.displayStatus && isNewSsnResolutionFlow ? (
              <p className='report--status' data-testid='report-title-status'>
                <b>{i18n.getStr('components.Report.Header.status.title')}:</b>{' '}
                {i18n.getStr(
                  `components.Report.Header.status.${report.displayStatus}`,
                )}
              </p>
            ) : null}
          </div>
        )}
        {report && (
          <ReportContainer
            report={report}
            international={international}
            expungement={expungement}
          />
        )}
      </MainContainer>
    </div>
  );
};
