import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox, Text } from '../fields';
import * as V from '../../lib/validations';
import * as N from '../../lib/normalizations';

import { VERIFICATION_FORM_NAME } from '../../constants';

class SsnCheckForm extends Component {
  onNoSsnUpdate = ({ target: { checked } }) => {
    if (checked) {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.change('ssn', '');
      // eslint-disable-next-line react/destructuring-assignment
      this.props.change('ssnConfirmation', '');
    }
  };

  render() {
    const { canDeclareNoSsn, noSsn } = this.props;

    return (
      <div>
        <div className='row'>
          <div className='col-md-6'>
            <Field
              type='tel'
              name='ssn'
              placeholder='placeholders.ssn'
              label='labels.ssn'
              component={Text}
              normalize={N.ssn}
              context='ssn'
              validate={[V.ssnRequired, V.ssnValid]}
              disabled={noSsn}
              onPaste={e => e.preventDefault()}
            />
          </div>
          <div className='col-md-6'>
            <Field
              type='tel'
              name='ssnConfirmation'
              placeholder='placeholders.ssn'
              label='labels.ssnConfirmation'
              component={Text}
              normalize={N.ssn}
              validate={[V.ssnRequired, V.ssnValid, V.ssnMatch]}
              disabled={noSsn}
              onPaste={e => e.preventDefault()}
            />
          </div>
        </div>
        {canDeclareNoSsn && (
          <div className='row'>
            <div className='col-md-12'>
              <Field
                type='checkbox'
                name='noSsn'
                label='labels.noSsn'
                className='inline-checkbox top-inline bottom-inline'
                onChange={this.onNoSsnUpdate}
                component={Checkbox}
                i18n
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

SsnCheckForm.propTypes = {
  noSsn: PropTypes.bool,
  change: PropTypes.func.isRequired,
  canDeclareNoSsn: PropTypes.bool,
};

SsnCheckForm.defaultProps = {
  noSsn: false,
  canDeclareNoSsn: false,
};

const SsnCheckReduxForm = reduxForm({
  form: VERIFICATION_FORM_NAME,
})(SsnCheckForm);

const selector = formValueSelector(VERIFICATION_FORM_NAME);
const mapStateToProps = state => ({
  ssn: selector(state, 'ssn') || '',
  ssnConfirmation: selector(state, 'ssnConfirmation') || '',
  noSsn: selector(state, 'noSsn') || false,
});

export default connect(mapStateToProps)(SsnCheckReduxForm);
