import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { Translate as T } from 'react-redux-i18n';
import Panel from '../../../Panels';
import { formatDateWithTimezone } from '../../../../lib/helpers';
import styles from './DrugAlcoholClearinghouse.module.css';
import { DrugAlcoholClearinghouseObject } from '../../../../types/report';

const DrugAlcoholClearinghouse = ({
  search,
}: {
  search: DrugAlcoholClearinghouseObject;
}) =>
  search && (
    <Panel
      data-testid='drug-alcohol-clearinghouse'
      title={i18n.getStr('components.drugAlcoholClearinghouse.title')}
      status={search.status}
    >
      {search.completedAt && (
        <div>
          <T value='components.drugAlcoholClearinghouse.description' />
          <span className='text-very-muted pl-2'>
            {formatDateWithTimezone(search.completedAt)}
          </span>
        </div>
      )}

      {search.queryResult && (
        <div className='mt-4 row md-4 mb-2'>
          <span className='text-very-muted col-md-3 mb-3 mb-md-0 pl-5'>
            <T value='components.drugAlcoholClearinghouse.queryResult' />
          </span>
          <span className='col-md-9 mb-3 mb-md-0'>{search.queryResult}</span>
        </div>
      )}

      {search.queryDetailsDocument && (
        <div className='row mb-md-4'>
          <span className='text-very-muted col-md-3 mb-3 mb-md-0 pl-5'>
            <T value='components.drugAlcoholClearinghouse.queryDetails' />
          </span>
          <span className='col-md-9 mb-3 mb-md-0'>
            <a
              href={search.queryDetailsDocument.downloadUri}
              target='_blank'
              rel='noreferrer'
            >
              {search.queryDetailsDocument.filename}
            </a>
          </span>
        </div>
      )}

      <div className={styles.styled} data-testid='disclaimer'>
        {i18n.renderHTML('components.drugAlcoholClearinghouse.disclaimer')}
      </div>
    </Panel>
  );

export default i18n.renderTranslation()(DrugAlcoholClearinghouse);
