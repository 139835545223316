export const CANADA_LICENSE_NUMBER = 'validations.canadaLicenseNumber';
export const DATE_AFTER = 'validations.dateAfter';
export const DATE_FORMAT = 'validations.dateFormat';
export const DATE_IN_THE_PAST = 'validations.dateInThePast';
export const EMAIL = 'validations.email';
export const EXPLANATION_REQUIRED = 'validations.explanationRequired';
export const LICENSE_NUMBER = 'validations.licenseNumber';
export const NAME = 'validations.name';
export const REQUIRED = 'validations.required';
export const REQUIRED_DOCUMENTS = 'validations.requiredDocuments';
export const SIGNATURE_FORMAT = 'validations.signatureFormat';
export const TOO_MANY_DOCUMENTS = 'validations.tooManyDocuments';
export const UNMATCHED_SSN = 'validations.unmatchedSSN';
export const VALID_CC = 'validations.validCC';
export const VALID_SSN = 'validations.validSSN';
export const ZIPCODE_FORMAT = 'validations.zipcodeFormat';
export const CONTENT_OR_DOC_REQUIRED = 'validations.contentOrDocRequired';
export const NUMBERS_NOT_ALLOWED = 'validations.numbersNotAllowed';
