import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import {
  getUnmaskedDobOrDefaultMasked,
  checkContextForUnmask,
} from './InternationalProfile';
import { dateWithTimezone } from '../lib/formatting';

const CanadaProfile = ({ report, pdf }) => (
  <div>
    <div className='row mb-md-4'>
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.firstName' />
        </span>
        <br />
        {report.candidate.firstName}
      </div>
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.middleName' />
        </span>
        <br />
        {report.candidate.middleName || '-'}
      </div>
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.lastName' />
        </span>
        <br />
        {report.candidate.lastName}
      </div>
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.dob' />
        </span>
        <br />
        {getUnmaskedDobOrDefaultMasked(
          report?.candidate?.dob,
          checkContextForUnmask(report),
        )}
      </div>
    </div>

    <div className='row mb-md-4'>
      {report.candidate.birthPlace && (
        <div className='col-md-3 mb-3 mb-md-0'>
          <span className='text-very-muted'>
            <T value='labels.birthPlace' />
          </span>
          <br />
          {report.candidate.birthPlace}
        </div>
      )}
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.phone' />
        </span>
        <br />
        {report.candidate.phone}
      </div>
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.email' />
        </span>
        <br />
        <div className='text-truncate' title={report.candidate.email}>
          {report.candidate.email}
        </div>
      </div>
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.driverLicenseNumber' />
        </span>
        <br />
        {report.candidate.driverLicenseNumber &&
        report.candidate.driverLicenseProvince ? (
          <span>
            {report.candidate.driverLicenseNumber} (
            {report.candidate.driverLicenseProvince})
          </span>
        ) : (
          <span>-</span>
        )}
      </div>
    </div>

    <div className='row mb-md-4'>
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.createdAt' />
        </span>
        <br />
        {dateWithTimezone(report, 'createdAt', pdf)}
      </div>
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='labels.completedAt' />
        </span>
        <br />
        {report.completedAt
          ? dateWithTimezone(report, 'completedAt', pdf)
          : '-'}
      </div>
      {report.revisedAt && (
        <div className='col-md-3 mb-3 mb-md-0'>
          <span className='text-very-muted'>
            <T value='labels.revisedAt' />
          </span>
          <br />
          {dateWithTimezone(report, 'revisedAt', pdf)}
        </div>
      )}
    </div>
  </div>
);

CanadaProfile.propTypes = {
  report: PropTypes.object.isRequired,
  pdf: PropTypes.bool,
};

CanadaProfile.defaultProps = {
  pdf: false,
};

export default CanadaProfile;
