import React from 'react';
import Panel from '../Panels';
import SsnCheckForm from './SsnCheckForm';
import ssnCard from '../../images/icons/ssn-card.svg';
import Verification, { connectToVerifications } from './VerificationBase';

class SsnCheckVerification extends Verification {
  renderCustomPanel() {
    const { doc } = this.getRenderAttributes();

    const {
      verification: { canDeclareNoSsn },
    } = this.props;

    return (
      <div>
        {doc.input && (
          <div data-testid='ssn-check'>
            <Panel
              i18n
              title='components.Report.Verification.SsnCheck.title'
              className='card-plain card-border'
            >
              <div className='col-md-12 text-center panel-header'>
                <img src={ssnCard} alt='ssn-card' />
              </div>
              <SsnCheckForm canDeclareNoSsn={canDeclareNoSsn} />
            </Panel>
          </div>
        )}
      </div>
    );
  }
}

export default connectToVerifications(SsnCheckVerification);
