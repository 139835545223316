import React from 'react';
import { ANALYTICS_EVENTS } from 'lib/analytics';
import { isValid as reduxFormIsValid } from 'redux-form';
import { connect, MapStateToPropsParam } from 'react-redux';
import { Translate as T } from 'react-redux-i18n';
import { datadogLogs } from '@datadog/browser-logs';
import MainContainer from '../../MainContainer';
import {
  handleNextPage as handleNextPageAction,
  handlePreviousPage as handlePreviousPageAction,
  trackAnalyticsEvent as trackAnalyticsEventAction,
} from '../../../actions';
import { ADA_CONTROL_ID, VERIFICATION_FORM_NAME } from '../../../constants';
import { VerificationWrapper } from './VerificationWrapper';
import TranslationsObject from '../../../lib/translations/portal_en.json';
import VerificationHeader from './VerificationHeader';

export interface VerificationProps {
  currentPage: number;
  expired: boolean;
  isSmallOrDown: boolean;
  isLogged: boolean;
  isValid: boolean;
  onSubmit: CallableFunction;
  steps: any[];
  submitting: boolean;
  verification: Record<string, any>;
  verificationError?: any;
  handleNextPage: (currentPage: number) => void;
  handlePreviousPage: (currentPage: number) => void;
  trackAnalyticsEvent: CallableFunction;
  newSsnResolutionFlow: boolean;
  showNav?: boolean;
}

interface VerificationState {
  showNav: boolean;
}

const errorMessage = (verificationError: any) => {
  if (
    typeof verificationError === 'string' &&
    Object.prototype.hasOwnProperty.call(TranslationsObject, verificationError)
  ) {
    return <T value={verificationError} />;
  }
  if (typeof verificationError === 'string') {
    return <>{verificationError}</>;
  }
  if (typeof verificationError?.message === 'string') {
    return <>{verificationError.message}</>;
  }
  return (
    <T value='components.Verification.DocumentsPanel.generic' dangerousHTML />
  );
};

class Verification extends React.Component<
  VerificationProps,
  VerificationState
> {
  constructor(props: VerificationProps) {
    super(props);

    this.wizardOnSubmit = this.wizardOnSubmit.bind(this);

    this.state = {
      showNav: props.showNav ?? true,
    };
  }

  getRenderAttributes() {
    const { showNav } = this.state;
    const {
      currentPage,
      steps,
      expired,
      isValid,
      isSmallOrDown,
      onSubmit,
      submitting,
      verification,
      handleNextPage,
      handlePreviousPage,
      isLogged,
      trackAnalyticsEvent,
    } = this.props;

    const canceled = !!verification.canceledAt;
    const completed = !!verification.completedAt;

    const firstStep = currentPage === 0;
    const isFinalStep = currentPage === steps.length - 1;

    const doc = steps[currentPage];
    const needUpload = doc?.input === 'upload';

    const showPreviousBtn = isLogged ? currentPage > 1 : currentPage > 0;
    const showNextStepBtn = !needUpload || (needUpload && isValid);

    return {
      showFirstPage: !isLogged,
      completed,
      currentPage,
      doc,
      firstStep,
      showNextStepBtn,
      showPreviousBtn,
      steps,
      expired,
      canceled,
      isFinalStep,
      isSmallOrDown,
      isValid,
      onSubmit,
      needUpload,
      submitting,
      verification,
      handleNextPage: handleNextPage.bind(this, currentPage),
      handlePreviousPage: handlePreviousPage.bind(this, currentPage),
      trackAnalyticsEvent,
      showNav,
    };
  }

  wizardOnSubmit(data: any) {
    const {
      isFinalStep,
      onSubmit,
      handleNextPage,
      firstStep,
      trackAnalyticsEvent,
    } = this.getRenderAttributes();

    if (firstStep) {
      trackAnalyticsEvent(
        ANALYTICS_EVENTS.EXCEPTION_FLOW_WELCOME_PAGE_COMPLETED,
      );
    }

    if (isFinalStep) {
      onSubmit(data);
    } else {
      handleNextPage();
    }
  }

  renderCustomPanel(): React.ReactNode {
    return null;
  }

  componentDidMount() {
    const existingScript = document.getElementById(ADA_CONTROL_ID);
    if (existingScript) {
      existingScript.remove();
    }

    const { verification } = this.props;
    datadogLogs.logger.info('[CP] Verification component mounted', {
      object: verification.object,
    });
  }

  render() {
    const {
      newSsnResolutionFlow,
      verification,
      steps,
      isLogged,
      verificationError,
      currentPage,
    } = this.props;
    const attributes = this.getRenderAttributes();
    const isWelcomePage = Object.keys(steps[currentPage]).length === 0;

    return (
      <MainContainer
        data-testid='verification-page'
        className={`verification__wrapper${
          newSsnResolutionFlow ? ' verification__wrapper--new-flow' : ''
        }`}
        header={
          Boolean(newSsnResolutionFlow) &&
          (attributes.completed ||
            (!isWelcomePage &&
              verification.object !== 'matrix_verification')) ? (
            <VerificationHeader
              companyName={verification.company}
              showLogin={Boolean(
                (verification.object !== 'ssn_check_failure' ||
                  steps[1].model === 'matrix_verification') &&
                  verification.completedAt,
              )}
              isLogged={isLogged}
            />
          ) : null
        }
      >
        {verificationError && (
          <>
            <div className='alert alert-danger' role='alert'>
              {errorMessage(verificationError)}
            </div>
          </>
        )}
        <VerificationWrapper
          {...attributes}
          wizardOnSubmit={this.wizardOnSubmit}
        >
          {this.renderCustomPanel()}
        </VerificationWrapper>
      </MainContainer>
    );
  }
}

export const mapStateToProps: MapStateToPropsParam<any, any, any> = (
  state: any,
) => {
  const { navigation } = state;
  const { steps, submitting, verification, expired } = state.verifications;
  const isLogged = state.portal?.loggedIn || false;
  const currentPage =
    !isLogged || navigation.currentPage > 0 ? navigation.currentPage : 1;

  return {
    isLogged,
    isSmallOrDown: state.browser?.lessThan?.medium,
    isValid: reduxFormIsValid(VERIFICATION_FORM_NAME)(state),
    currentPage: steps.length - 1 < currentPage ? 0 : currentPage,
    steps,
    expired: expired ?? false,
    submitting,
    verification,
  };
};

export const connectToVerifications = (component: any, actions: any = {}) => {
  return connect(mapStateToProps, {
    handleNextPage: handleNextPageAction,
    handlePreviousPage: handlePreviousPageAction,
    trackAnalyticsEvent: trackAnalyticsEventAction || (() => {}),
    ...actions,
  })(component);
};

export default Verification;
