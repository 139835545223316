import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { Report } from 'types/report';
import Aliases from './Screenings/Aliases';
import EmploymentVerification from './Screenings/EmploymentVerification';
import EducationVerification from './Screenings/EducationVerification';
import MotorVehicleReport from './Screenings/MotorVehicleReport';
import NationalCriminalSearch from './Screenings/NationalCriminalSearch';
import PersonalReferenceVerification from './Screenings/PersonalReferenceVerification';
import ProfessionalReferenceVerification from './Screenings/ProfessionalReferenceVerification';
import SSNTrace from './Screenings/SSNTrace';
import FacisVerification from './Screenings/FacisVerification';
import HealthcareSanctionsSearch from './Screenings/HealthcareSanctionsSearch';

import Search from './Search/Search';
import IntlGlobalWatchlistSearch from './Screenings/International/IntlGlobalWatchlistSearch';
import SearchCollection from './Search/SearchCollection';
import SexOffenderSearch from './Screenings/SexOffenderSearch';

import CandidateStories from './Screenings/CandidateStories';
import IntlAdverseMediaSearches from './Screenings/International/IntlAdverseMediaSearches';
import IntlCriminalSearchesV2 from './Screenings/International/IntlCriminalSearchesV2';
import IntlEducationVerification from './Screenings/International/IntlEducationVerification';
import IntlEmploymentVerification from './Screenings/International/IntlEmploymentVerification';
import IntlIdentityDocumentValidation from './Screenings/International/IntlIdentityDocumentValidation';
import IntlCanadaBcEnhancedCriminalSearch from './Screenings/International/IntlCanadaBcEnhancedCriminalSearch';
import IntlRightToWork from './Screenings/International/IntlRightToWork';
import IntlMotorVehicleReports from './Screenings/International/IntlMotorVehicleReports';
import { onlySSN } from '../../../lib/helpers';

type SearchType = {
  status: string;
};

const filterByNonCanceled = (array: Array<SearchType> = []) => {
  return array.filter(search => search.status !== 'canceled');
};

interface CriminalScreeningsProps {
  report: Report;
}
const CriminalScreenings = ({ report }: CriminalScreeningsProps) => (
  <>
    <Aliases ssnTrace={report.ssnTrace} />
    {!onlySSN(report) && <SSNTrace search={report.ssnTrace} />}
    <Search
      dataTestId='arrest-searches'
      type='arrest'
      title={i18n.getStr('components.criminalScreenings.arrestSearch')}
      search={report.arrestSearch}
    />
    <Search
      dataTestId='civil-searches'
      type='eviction'
      title={i18n.getStr('components.criminalScreenings.civilSearch')}
      search={report.evictionSearch}
    />
    <SexOffenderSearch search={report.sexOffenderSearch} />
    <Search
      dataTestId='global-watchlist-searches'
      type='criminal'
      title={i18n.getStr('components.criminalScreenings.globalWatchlistSearch')}
      search={report.terroristWatchlistSearch}
    />
    <HealthcareSanctionsSearch searches={report.healthcareSanctionsSearches} />
    <SearchCollection
      dataTestId='international-criminal-searches'
      type='criminal'
      title={i18n.getStr('components.criminalScreenings.internationalSearches')}
      searches={report.internationalCriminalSearches}
    />
    <NationalCriminalSearch
      type='criminal'
      search={report.nationalCriminalSearch}
    />
    <SearchCollection
      type='criminal'
      title={i18n.getStr('components.criminalScreenings.stateSearch')}
      hideSuspended
      searches={report.stateCriminalSearches}
    />
    <SearchCollection
      type='criminal'
      title={i18n.getStr('components.criminalScreenings.pointerStateSearch')}
      searches={report.pointerStateCriminalSearches}
    />
    <Search
      dataTestId='federal-searches'
      showFilteredByAdjudicationRecords
      type='criminal'
      title={i18n.getStr('components.criminalScreenings.federalSearch')}
      search={report.federalCriminalSearch}
    />
    <SearchCollection
      type='criminal'
      title={i18n.getStr(
        'components.criminalScreenings.federalDistrictSearches',
      )}
      hideSuspended
      searches={report.federalDistrictCriminalSearches}
    />
    <Search
      dataTestId='federal-civil-searches'
      type='civil'
      title={i18n.getStr('components.criminalScreenings.federalCivilSearch')}
      search={report.federalCivilSearch}
    />
    <SearchCollection
      type='civil'
      title={i18n.getStr(
        'components.criminalScreenings.federalDistrictCivilSearches',
      )}
      hideSuspended
      searches={report.federalDistrictCivilSearches}
    />
    <SearchCollection
      type='criminal'
      title={i18n.getStr('components.criminalScreenings.countySearches')}
      hideSuspended
      searches={filterByNonCanceled(report.countyCriminalSearches)}
    />
    <SearchCollection
      dataTestId='county-civil-searches'
      type='civil'
      title={i18n.getStr('components.criminalScreenings.countyCivilSearches')}
      searches={filterByNonCanceled(report.countyCivilSearches)}
    />
    <SearchCollection
      type='criminal'
      title={i18n.getStr('components.criminalScreenings.municipalSearches')}
      searches={filterByNonCanceled(report.municipalCriminalSearches)}
    />
    <SearchCollection
      type='childAbuse'
      title={i18n.getStr('components.criminalScreenings.childAbuseSearch')}
      hideSuspended
      searches={filterByNonCanceled(report.childAbuseSearches)}
    />
    <IntlGlobalWatchlistSearch
      search={report.internationalGlobalWatchlistSearch}
    />
    <IntlAdverseMediaSearches
      searches={filterByNonCanceled(report.internationalAdverseMediaSearches)}
    />
    <IntlCriminalSearchesV2
      searches={filterByNonCanceled(report.internationalCriminalSearchesV2)}
    />
    <IntlEducationVerification
      search={report.internationalEducationVerification}
    />
    <IntlEmploymentVerification
      search={report.internationalEmploymentVerification}
    />
    <IntlIdentityDocumentValidation
      search={report.internationalIdentityDocumentValidation}
    />
    <IntlCanadaBcEnhancedCriminalSearch
      search={report.internationalCanadaBcEnhancedCriminalSearch}
    />
    <IntlRightToWork search={report.internationalRightToWork} />
    <IntlMotorVehicleReports
      searches={report.internationalMotorVehicleReports}
    />
    <MotorVehicleReport search={report.motorVehicleReport} />
    <EmploymentVerification search={report.employmentVerification} />
    <EducationVerification search={report.educationVerification} />
    <PersonalReferenceVerification
      searches={filterByNonCanceled(report.personalReferenceVerifications)}
    />
    <ProfessionalReferenceVerification
      searches={filterByNonCanceled(report.professionalReferenceVerifications)}
    />
    <FacisVerification search={report.facisSearch} />
    {report.account && (
      <CandidateStories
        companyName={report.account.name}
        stories={report.candidateStories || null}
        candidate={report.candidate}
      />
    )}
  </>
);

CriminalScreenings.propTypes = {
  report: PropTypes.object.isRequired,
};

export default CriminalScreenings;
