import React from 'react';
import { SearchEntity } from './types';
import {
  EducationVerificationObject,
  SexOffenderSearchObject,
} from '../../../../types/report';

export interface WithNonCanceledSearchProps {
  search?: SearchEntity | EducationVerificationObject;
}

const withNonCanceledSearch = (
  WrappedComponent: React.ComponentType<{
    search:
      | SearchEntity
      | EducationVerificationObject
      | SexOffenderSearchObject;
  }>,
) => {
  const WithNonCanceledSearch = ({ search }: WithNonCanceledSearchProps) => {
    if (!search || search.status === 'canceled') return <></>;
    return <WrappedComponent search={search} />;
  };

  return WithNonCanceledSearch;
};

export default withNonCanceledSearch;
