import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { uuid4 } from '@sentry/utils';
import Panel from '../../../../Panels';
import { InternationalCreditObject } from '../../../../../types/report';
import InternationalCreditReportRecord from './InternationalCreditReportRecord';

interface InternationalCreditProps {
  search: InternationalCreditObject;
}

const InternationalCreditReport = ({ search }: InternationalCreditProps) => {
  const internationalCreditObject = search?.records?.find(
    (creditRecord: { countryName: string }) => creditRecord.countryName,
  );
  return (
    !!internationalCreditObject && (
      <Panel
        data-testid='international-credit-report'
        title={i18n.getStr('components.internationalCreditReport')}
        status={search.status}
      >
        {search.records.map((record, i) => (
          <InternationalCreditReportRecord
            search={search}
            record={record}
            lastLine={i === search.records.length - 1}
            key={uuid4()}
          />
        ))}
      </Panel>
    )
  );
};

InternationalCreditReport.propTypes = {
  search: PropTypes.object,
};

InternationalCreditReport.defaultProps = {
  search: {} as object,
};

export default i18n.renderTranslation()(InternationalCreditReport);
