import PropTypes from 'prop-types';
import React from 'react';
import { moment, i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';
import { StatusTypes } from '@dashboard-experience/utils';
import {
  InternationalCreditObject,
  InternationalCreditRecordObject,
} from '../../../../../types/report';
import {
  InternationalCreditReportRecordBox,
  InternationalCreditReportRecordLine,
  InternationalCreditGrayCaption,
  InternationalCreditBoldCaption,
} from './InternationalCreditStyledComponents';

interface InternationalCreditReportRecordProps {
  record: InternationalCreditRecordObject;
  lastLine: boolean;
  search: InternationalCreditObject;
}

const InternationalCreditReportRecord = ({
  record,
  lastLine,
  search,
}: InternationalCreditReportRecordProps) =>
  !!record && (
    <div>
      <InternationalCreditReportRecordBox>
        <InternationalCreditReportRecordLine>
          <InternationalCreditBoldCaption>
            {record.countryName}{' '}
            {i18n.getStr('components.internationalCreditReportCaption')}
          </InternationalCreditBoldCaption>
          <br />
          <br />
          <InternationalCreditGrayCaption>
            {moment(search.completedAt).format('MMM D, YYYY h:m A')}
            {' ('}
            {moment(search.completedAt).fromNow()}
            {') '}
          </InternationalCreditGrayCaption>
          <br />
          <br />
          <M.Link href={record.pdfUrl}>
            {i18n.getStr('components.viewInternationalCreditReportLink')}
          </M.Link>
        </InternationalCreditReportRecordLine>
        <div>
          <M.StatusBadge
            statusType={StatusTypes.Legacy}
            status={search.status}
          />
        </div>
      </InternationalCreditReportRecordBox>
      {!lastLine && <hr />}
    </div>
  );

InternationalCreditReportRecord.propTypes = {
  record: PropTypes.object,
  lastLine: PropTypes.bool,
};

InternationalCreditReportRecord.defaultProps = {
  record: {},
  lastLine: false,
};

export default i18n.renderTranslation()(InternationalCreditReportRecord);
