import { useEffect } from 'react';
import { ADA_JS_SUPPORT_SCRIPT, ADA_SCRIPT_ID } from '../../../constants';

export const loadAda = () => {
  const existingScript = document.getElementById(ADA_SCRIPT_ID);

  if (existingScript) {
    return;
  }

  const script = document.createElement('script');
  script.src = ADA_JS_SUPPORT_SCRIPT;
  script.id = ADA_SCRIPT_ID;
  script.setAttribute('data-testid', ADA_SCRIPT_ID);
  script.dataset.handle = 'checkr';
  const candidateIds = window.sessionStorage.getItem('candidate_ids');
  const userAccessToken = window.sessionStorage.getItem('token');
  (window as any).adaSettings = {
    sensitiveMetaFields: {
      cxUserAccessToken: userAccessToken,
    },
    metaFields: {
      cxCandidateIds: candidateIds,
    },
    handle: 'checkr',
  };
  document.body.appendChild(script);
};

export const useAda = (location: string) => {
  useEffect(() => {
    if (!location.includes('/verification')) {
      loadAda();
    }
  }, [location]);
};
