import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { moment } from '@international/mastodon-i18n';
import { longDatePattern } from 'lib/dateFormat';

const DrugScreeningAppointment = ({ report: { drugScreening } }) => {
  if (!drugScreening) {
    return null;
  }
  const { appointment, status } = drugScreening;
  return appointment ? (
    <div className='panel-details row mb-md-4'>
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='components.drugScreening.drugScreenLab' />
        </span>
        <br />
        {appointment.name}
        <br />
        {appointment.street} {appointment.unit}
        <br />
        {`${appointment.city}, ${appointment.state} ${appointment.zipcode}`}
      </div>
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='components.drugScreening.labHoursOfOperation' />
        </span>
        <br />
        {appointment.locationSchedule.split('|').map(s => (
          <span key={s}>
            {s}
            <br />
          </span>
        ))}
      </div>
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='components.drugScreening.scheduled' />
        </span>
        <br />
        {moment(appointment.date).format(longDatePattern())}
      </div>
      <div className='col-md-3 mb-3 mb-md-0'>
        <span className='text-very-muted'>
          <T value='components.drugScreening.drugScreenStatus' />
        </span>
        <br />
        {status}
      </div>
    </div>
  ) : null;
};

DrugScreeningAppointment.propTypes = {
  report: PropTypes.object.isRequired,
};

export default DrugScreeningAppointment;
