import React, { ReactNode } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

interface TitledContainerProps {
  titleKey: string;
  children: ReactNode;
}

export const TitledContainer: React.FC<TitledContainerProps> = ({
  titleKey,
  children,
}) => (
  <M.Container>
    <h5
      style={{
        textAlign: 'left',
      }}
    >
      {i18n.getStr(titleKey)}
    </h5>
    {children}
  </M.Container>
);
