import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import { trackAnalyticsEvent } from 'actions/analytics';
import Panel from '../Panels';
import {
  CHECKR_APPLICANTS_EU_BASE_URL,
  CHECKR_APPLICANTS_US_BASE_URL,
  CHECKR_REGION,
  RECAPTCHA_SITE_KEY,
} from '../../constants';
import { ANALYTICS_EVENTS } from '../../lib/analytics';

import { Select, SensitiveInput, Text } from '../fields';
import * as V from '../../lib/validations';
import { slashDatePattern } from '../../lib/dateFormat';

const ButtonStyled = styled(M.Button)`
  float: right;
`;

const ButtonWrapperStyled = styled.div`
  &::after {
    display: block;
    clear: both;
    content: '';
  }
`;

const PanelStyled = styled(Panel)`
  background-color: ${colors.white};
  border: 1px solid ${colors.coolBlack10};
`;

const ErrorAlertStyled = styled.div`
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  background-color: ${colors.dangerRedBg};
  border-color: ${colors.adverseOrangeBg};
  color: ${colors.adverseOrangeHover};

  .mastodon-icon {
    fill: ${colors.pink04};
    margin: 2px 0 0;
    width: 1rem;
    height: 1rem;
    vertical-align: top;
  }
`;

const InputStyled = styled.div`
  width: 100%;
  margin-left: 15px;

  @media (min-width: 768px) {
    flex: 0 0 30%;
    max-width: 30%;
  }
`;

const INTL_LOGIN_FORM_NAME = 'intlLogin';

const idTypes = [
  { value: '', name: 'options.selectAnIdType', i18n: true },
  { value: 'passport', name: 'options.idTypes.passport', i18n: true },
  {
    value: 'driversLicense',
    name: 'options.idTypes.driversLicense',
    i18n: true,
  },
  { value: 'nationalId', name: 'options.idTypes.nationalId', i18n: true },
];

interface IntlLoginFormValues {
  countryOfCitizenship: string;
  email: string;
  dob: string;
  idType: string;
  idNumber: string;
  recaptchaCode: string;
}

interface IntlLoginFormProps extends InjectedFormProps<IntlLoginFormValues> {
  fetchError?: string;
  processing: boolean;
  loginSource: string;
  handleSubmit: () => void;
}

const IntlLoginForm: React.FC<IntlLoginFormProps> = ({
  fetchError,
  processing,
  handleSubmit,
  valid,
  loginSource,
  anyTouched,
  change,
}) => {
  const [disabledButton, setDisabledButton] = React.useState(true);
  const [loaded, setLoaded] = React.useState(false);
  const dispatch = useDispatch();
  const [idType, setIdType] = useState('');
  const updateIdType = useCallback(
    ({ target: { value } }: { target: { value: string } }) => setIdType(value),
    [setIdType],
  );
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const handleClickSubmit = async (e: React.ChangeEvent) => {
    e.preventDefault();

    dispatch(
      trackAnalyticsEvent(
        ANALYTICS_EVENTS.CANDIDATE_PORTAL_LOGIN_ATTEMPTED,
        {},
      ),
    );

    try {
      const recaptchaCode = await recaptchaRef.current?.executeAsync();
      change('recaptchaCode', recaptchaCode);
      handleSubmit();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('ReCAPTCHA error: ', error);
    } finally {
      change('recaptchaCode', '');
      recaptchaRef.current?.reset();
    }
  };

  const dateFormat = slashDatePattern();

  const redirectLink =
    CHECKR_REGION === 'EU'
      ? i18n.renderHTML(
          `components.${loginSource}.SGPHRedirectLink`,
          `${CHECKR_APPLICANTS_US_BASE_URL}/international`,
        )
      : i18n.renderHTML(
          `components.${loginSource}.internationalRedirectLink`,
          `${CHECKR_APPLICANTS_EU_BASE_URL}/international`,
        );

  const USRedirectLink = i18n.renderHTML(
    `components.${loginSource}.USRedirectLink`,
    CHECKR_APPLICANTS_US_BASE_URL,
  );

  const validateDate = useMemo(
    () => V.validDate(dateFormat, true),
    [dateFormat],
  );
  React.useEffect(() => {
    setDisabledButton(!anyTouched || !valid);
  }, [anyTouched, valid]);

  React.useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <form data-testid='international-login-form'>
      <Panel>
        <span>{i18n.renderHTML(`components.${loginSource}.welcomeText`)}</span>
        <span>{redirectLink}</span>
        <span>{USRedirectLink}</span>
      </Panel>
      {fetchError && (
        <ErrorAlertStyled role='alert'>
          <div>
            <M.Icon icon='WarningAltFilled' />{' '}
            <b>
              <T value='components.LoginForm.errorTitle' />
            </b>
          </div>
          <T value={`components.${loginSource}.${fetchError}`} dangerousHTML />
        </ErrorAlertStyled>
      )}
      <PanelStyled i18n title='components.InternationalLoginForm.title'>
        <M.Container.Row>
          <InputStyled>
            <Field
              type='email'
              name='email'
              label='labels.email'
              placeholder='placeholders.email2'
              component={Text}
              validate={[V.required, V.emailFormat]}
            />
          </InputStyled>
          <InputStyled>
            <Field
              type='input'
              name='dob'
              label='labels.dobFull'
              dateformat={dateFormat}
              placeholder={dateFormat}
              component={Text}
              validate={[validateDate]}
            />
          </InputStyled>
          <InputStyled>
            <Field
              type='select'
              name='countryOfCitizenship'
              label='labels.citizenship'
              component={Select}
              defaultValue={i18n.getStr('options.selectACountry')}
              validate={[V.required]}
              options={[
                { name: 'options.selectACountry', value: '', i18n: true },
                ...i18n.getCountries(),
              ]}
            />
          </InputStyled>
        </M.Container.Row>
        <M.Container.Row>
          <InputStyled>
            <Field
              type='select'
              name='idType'
              label='labels.idType'
              component={Select}
              validate={[V.required]}
              onChange={updateIdType}
              options={idTypes}
            />
          </InputStyled>
          {idType && (
            <InputStyled style={{ marginLeft: 15 }}>
              <Field
                type='input'
                name='idNumber'
                label={'labels.idTypes.'.concat(idType)}
                placeholder={'placeholders.idTypes.'.concat(idType)}
                component={SensitiveInput}
                disabled={!loaded}
                validate={[V.required]}
              />
            </InputStyled>
          )}
        </M.Container.Row>

        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={RECAPTCHA_SITE_KEY}
          size='invisible'
          badge='inline'
          hl={i18n.getGoogleRecaptchaLocale()}
          onErrored={() => {
            // eslint-disable-next-line no-console
            console.error('ReCAPTCHA error: ');
          }}
        />
      </PanelStyled>
      <ButtonWrapperStyled data-testid='continue-button'>
        <ButtonStyled
          type='submit'
          onClick={handleClickSubmit}
          disabled={processing || disabledButton}
          kind={`${disabledButton ? 'secondary' : 'primary'}`}
        >
          {processing || !loaded ? (
            <M.LoadingInline
              className={`button-loading-icon ${
                disabledButton ? '' : 'button-loading-icon--primary'
              }`}
            />
          ) : (
            <T value='buttons.continue' />
          )}
        </ButtonStyled>
      </ButtonWrapperStyled>
    </form>
  );
};

IntlLoginForm.propTypes = {
  fetchError: PropTypes.string,
  processing: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  anyTouched: PropTypes.bool.isRequired,
  loginSource: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
};

IntlLoginForm.defaultProps = {
  fetchError: '',
};

const IntlLoginReduxForm = reduxForm<IntlLoginFormValues, IntlLoginFormProps>({
  form: INTL_LOGIN_FORM_NAME,
})(IntlLoginForm);

export default i18n.renderTranslation()(IntlLoginReduxForm);
