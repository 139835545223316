import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from '@dashboard-experience/react-flagr';
import Cookie from 'js-cookie';
import '@dashboard-experience/mastodon/lib/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { setInternational } from 'actions';
import { useLocation } from 'react-router-dom';

import { isInternational as getIsInternational } from '../lib/helpers';
import { ACCOUNT_URI_COOKIE_NAME, ENV, FLAGR_URL } from '../constants';
import Header from './Header';
import Footer from './Footer';

const bothAccountURIsPresentAndDistinct = (cookieAccountURI, accountURI) => {
  return !!(cookieAccountURI && accountURI && cookieAccountURI !== accountURI);
};

export const getAccountUri = (accountURI = '', viewingLoginScreen = false) => {
  if (viewingLoginScreen) {
    Cookie.remove(ACCOUNT_URI_COOKIE_NAME);
    return '';
  }

  const cookieAccountURI = Cookie.get(ACCOUNT_URI_COOKIE_NAME) || '';
  if (bothAccountURIsPresentAndDistinct(cookieAccountURI, accountURI)) {
    Cookie.set(ACCOUNT_URI_COOKIE_NAME, accountURI);
    return accountURI;
  }
  if (!cookieAccountURI) {
    Cookie.set(ACCOUNT_URI_COOKIE_NAME, accountURI);
    return accountURI;
  }
  return cookieAccountURI;
};

const getReduxState = state => {
  const firstReport = state?.portal?.reports[0];
  return {
    isInternational:
      state.international.international || getIsInternational(state.portal),
    accountUri: firstReport?.account?.uriName,
    candidateEmail: firstReport?.candidate?.email,
  };
};

const App = ({ children }) => {
  const { isInternational, accountUri, candidateEmail } =
    useSelector(getReduxState);
  const { hash } = useLocation();
  const isLogin = hash?.indexOf('login') >= 0;
  const accountURI = getAccountUri(accountUri, isLogin);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setInternational(isInternational));
  }, [isInternational, dispatch]);

  return (
    <Provider
      url={FLAGR_URL || ''}
      entityId='env'
      entityContext={{
        env: ENV,
        account_uri: accountURI,
        locationHost: window.location.host,
        candidateEmail,
      }}
    >
      <div>
        <Header isInternational={isInternational} />
        {children}
        <Footer international={isInternational} />
      </div>
    </Provider>
  );
};

App.propTypes = {
  children: PropTypes.node,
};

App.defaultProps = {
  children: [],
};

export default App;
