import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

interface UnorderedListProps {
  textKeys: string[];
}

export const UnorderedList: React.FC<UnorderedListProps> = ({ textKeys }) => {
  return (
    <M.UnorderedList>
      {textKeys.map(key => {
        return (
          <M.ListItem key={key}>
            <p className='p3'>{i18n.getStr(key)}</p>
          </M.ListItem>
        );
      })}
    </M.UnorderedList>
  );
};
