import React from 'react';
import { Route, createRoutesFromElements } from 'react-router-dom';

import { CHECKR_REGION, ENV } from '../constants';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import International from './pages/International';
import Verifications from './pages/Verifications';
import VerificationsSubscription from '../containers/Verifications/Subscription';
import HiddenDisputeTree from './pages/HiddenDisputeTree';
import Privacy from './pages/Privacy';
import DeleteData from '../components/Privacy/DeleteData';
import AccessData from '../components/Privacy/AccessData';
import PrivacyLogin from './pages/Privacy/Login';
import InternationalPrivacy from './pages/International/Privacy';
import InternationalDeleteData from '../components/Privacy/InternationalDeleteData';
import InternationalPrivacyLogin from './pages/International/Privacy/Login';
import VerificationsStatus from '../containers/Verifications/Status';
import SingleReport from '../containers/SingleReport/SingleReport';
import MultipleReports from '../containers/ReportsOverview';
import AuthenticateCandidate from './pages/AuthenticateCandidate';
import Login from './pages/Login';
import InternationalLogin from './pages/International/Login';
import Layout from './pages/Layout';
import MockReport from './pages/MockReport';
import InstantExceptions from '../containers/InstantExceptions';

export const PortalRoutes = (
  <Route>
    <Route element={<Layout />}>
      <Route path='/' element={<Home />}>
        <Route index element={<MultipleReports />} />
        <Route path='reports' element={<MultipleReports />} />
        <Route path='reports/:reportId' element={<SingleReport />} />
      </Route>

      {CHECKR_REGION !== 'EU' && (
        <Route
          path='authenticate-candidate'
          element={<AuthenticateCandidate />}
        />
      )}

      <Route path='/login' element={<Login />} />

      <Route path='/verifications' element={<Verifications />}>
        <Route path=':verificationId' element={<VerificationsStatus />} />
        <Route
          path=':verificationId/unsubscribe'
          element={<VerificationsSubscription />}
        />
      </Route>

      <Route path='/hidden_dispute_tree' element={<HiddenDisputeTree />} />

      <Route path='/international/login' element={<InternationalLogin />} />

      <Route path='/international' element={<International />}>
        <Route index element={<MultipleReports />} />
        <Route path='reports' element={<MultipleReports />} />
      </Route>
      <Route
        path='/instant_exceptions/:reportId'
        element={<InstantExceptions />}
      />
    </Route>
    <Route path='/privacy' element={<Privacy />}>
      <Route path='delete' element={<DeleteData />} />
      <Route path='access' element={<AccessData />} />
      <Route path='login' element={<PrivacyLogin />} />
    </Route>
    <Route path='/international/privacy' element={<InternationalPrivacy />}>
      <Route path='delete' element={<InternationalDeleteData />} />
      <Route path='login' element={<InternationalPrivacyLogin />} />
    </Route>

    {ENV === 'development' && <Route path='/mock' element={<MockReport />} />}

    <Route path='*' element={<NotFound />} />
  </Route>
);

const appRoutes = createRoutesFromElements(PortalRoutes);

export default appRoutes;
