import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import Panel from '../../../Panels';
import { SSNTraceObject } from '../../../../types/report';

interface AliasesProps {
  ssnTrace: SSNTraceObject;
}

const Aliases = ({ ssnTrace }: AliasesProps) =>
  !!ssnTrace &&
  ssnTrace.aliases.length > 0 &&
  ssnTrace.status !== 'canceled' && (
    <Panel title={i18n.getStr('components.aliases')} className='card-border'>
      {ssnTrace.aliases.map((alias, i) => (
        <p className='list-group-item-text' key={`p-${i + 1}`}>
          {alias.firstName} {alias.middleName} {alias.lastName}
        </p>
      ))}
    </Panel>
  );

Aliases.propTypes = {
  ssnTrace: PropTypes.object,
};

Aliases.defaultProps = {
  ssnTrace: {},
};

export default i18n.renderTranslation()(Aliases);
