import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import {
  Field,
  isValid as reduxFormIsValid,
  WrappedFieldProps,
} from 'redux-form';

import {
  VERIFICATION_FORM_NAME,
  DISPUTE_FORM_NAME,
  CANDIDATE_STORY_FORM_NAME,
} from '../../../constants';
import UploadFieldAdapter from './UploadFieldAdapter';

interface UploadFieldProps {
  name: string;
  options: any;
  buttonText?:
    | {
        default: string;
        more?: string;
      }
    | string;
  labelText?: string;
  disabled?: boolean;
  isValid: boolean;
  onChange?: (...args: any[]) => void;
  validate?: (value?: any[]) => string | undefined;
}

const Upload: React.FC<Partial<WrappedFieldProps> & UploadFieldProps> = ({
  name,
  buttonText,
  labelText,
  disabled = false,
  isValid,
  options,
  validate,
  onChange,
}) => {
  return (
    <Field
      component={UploadFieldAdapter}
      name={name}
      context='verifications'
      options={options}
      disabled={disabled}
      buttonText={buttonText}
      labelText={labelText}
      isValid={isValid}
      validate={validate}
      onChange={onChange}
    />
  );
};

const mapStateToProps: MapStateToPropsParam<unknown, { context: string }> = (
  state,
  ownProps,
) => {
  let formName;
  if (ownProps.context === 'verifications') {
    formName = VERIFICATION_FORM_NAME;
  } else if (ownProps.context === 'candidate-stories') {
    formName = CANDIDATE_STORY_FORM_NAME;
  } else {
    formName = DISPUTE_FORM_NAME;
  }

  return { isValid: reduxFormIsValid(formName)(state) };
};

export default connect<any, null, { context: string }>(mapStateToProps)(Upload);
