import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';
import Panel from '../../Panels';
import DownloadPDFDocuments from './DownloadPDFDocuments';
import DrugScreeningAppointment from './Screenings/DrugScreeningAppointment';
import HealthScreeningInformation from './Screenings/HealthScreeningInformation';
import { includesAppointment, onlySSN } from '../../../lib/helpers';
import CandidateProfileSelector from './CandidateProfiles/CandidateProfileSelector';

const getDonorPass = documents =>
  documents.find(d => d.type === 'drug_screening_donor_pass');

const Information = ({ canada, pdf, report, account, international }) => (
  <span>
    <Panel
      data-testid='information-panel'
      className='card-plain card-border'
      title={i18n.getStr(
        'components.Report.ScheduleComponent.infoPanel.reportInformation',
      )}
      status={onlySSN(report) ? '' : report.status}
      statusLabel
    >
      <CandidateProfileSelector
        canada={canada}
        international={international}
        pdf={pdf}
        report={report}
        segmentationEnabled={
          !!(
            (report.account && report.account.segmentationEnabled) ||
            (account && account.segmentationEnabled)
          )
        }
      />

      <DrugScreeningAppointment report={report} />
      {report.status === 'pending' && getDonorPass(report.documents) && (
        <div className='float-right'>
          <M.Link
            href={getDonorPass(report.documents).downloadUri}
            target='_blank'
            rel='noopener noreferrer'
          >
            <M.Button kind='secondary'>
              <T value='buttons.downloadDownloadDonorPass' />
            </M.Button>
          </M.Link>
        </div>
      )}

      {!pdf && report.status !== 'suspended' && report.status !== 'pending' && (
        <div className='float-right'>
          <DownloadPDFDocuments
            consentLink={report.consentLink}
            documents={report.documents}
            candidate={report.candidate}
          />
        </div>
      )}
    </Panel>
    {includesAppointment(report) && !pdf && (
      <Panel
        className='card-plain card-border card-secondary card-screening-information'
        title={i18n.getStr(
          'components.Report.ScheduleComponent.infoPanel.healthScreeningTitle',
        )}
      >
        <HealthScreeningInformation report={report} />
      </Panel>
    )}
  </span>
);

Information.propTypes = {
  canada: PropTypes.bool,
  international: PropTypes.bool,
  pdf: PropTypes.bool,
  report: PropTypes.object,
  account: PropTypes.object,
};

Information.defaultProps = {
  canada: false,
  international: false,
  pdf: false,
  report: {},
  account: {},
};

export default Information;
