import PropTypes from 'prop-types';
import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

import { Translate as T } from 'react-redux-i18n';
import Panel from '../../../Panels';
import Attribute from '../Attribute';
import { dateIfDate } from '../lib/formatting';
import { MVR_INCIDENT_FIELDS } from '../../../../constants';
import MedicalCertificates from './MedicalCertificates';
import { getRandomKey } from '../../../../lib/helpers';

const AdditionalInfo = ({ attributes }) => (
  <div className='additional-info-box'>
    <div className='additional-info-text'>
      <T value='components.Report.MotorVehicleReport.additionalInfo' />
      <br />
      <small>
        <T value='components.Report.MotorVehicleReport.licenseProvidedByTheCandidate' />
      </small>
    </div>
    {Object.keys(attributes).map((key, i) => (
      <Attribute
        key={`attribute-${i + 1}`}
        attr={i18n.getStr(key)}
        value={attributes[key]}
      />
    ))}
  </div>
);

const CovidNotice = () => (
  <div className='card covid-19-notice'>
    <div className='card-body'>
      <p>
        <strong>
          <M.Icon icon='WarningAltFilled' />
          <T value='components.Report.MotorVehicleReport.covidExtensionNotice.text' />
        </strong>
      </p>
    </div>
  </div>
);

AdditionalInfo.propTypes = {
  attributes: PropTypes.object.isRequired,
};

class MotorVehicleReport extends React.Component {
  nameMatches(licenseReport) {
    return (
      licenseReport.nameMatches === undefined ||
      licenseReport.nameMatches === true
    );
  }

  dobMatches(licenseReport) {
    return (
      licenseReport.dobMatches === undefined ||
      licenseReport.dobMatches === true
    );
  }

  showLicenseReportData(licenseReport) {
    return this.nameMatches(licenseReport) && this.dobMatches(licenseReport);
  }

  licenseResultStatus(licenseReport) {
    if (!this.nameMatches(licenseReport) && !this.dobMatches(licenseReport)) {
      return i18n.getStr(
        'components.Report.MotorVehicleReport.errors.nameAndDobMismatch',
      );
    }
    if (!this.nameMatches(licenseReport)) {
      return i18n.getStr(
        'components.Report.MotorVehicleReport.errors.nameMismatch',
      );
    }
    if (!this.dobMatches(licenseReport)) {
      return i18n.getStr(
        'components.Report.MotorVehicleReport.errors.dobMismatch',
      );
    }

    return undefined;
  }

  renderIncidentAttributes(attrs) {
    return MVR_INCIDENT_FIELDS.map((key, i) => {
      let value = attrs[key];

      if (key === 'dispositionDate') {
        // convictionDate value on violations should be displayed as "Disposition Date"
        value = value || attrs.convictionDate;
      }

      return <Attribute key={getRandomKey()} attr={key} value={value} />;
    });
  }

  renderCdlisRecord(cdlisRecord) {
    return (
      <Panel
        title={i18n.getStr('components.Report.MotorVehicleReport.cdlisResults')}
        className='cdlis-record card-plain card-border'
        key='cdlis-records-panel'
      >
        {cdlisRecord.driverLicenses.map((driverLicense, i) => (
          <div
            className='cdlis-attributes card-plain'
            key={`cdlis-license-panel-${i + 1}`}
          >
            <div
              className='cdlis-attributes mt-2 mb-5'
              key={`cdlis-attributes-${i + 1}`}
            >
              <Attribute
                attr={i18n.getStr(
                  'components.Report.MotorVehicleReport.driverName',
                )}
                value={`${driverLicense.driver.name.firstName}
                  ${driverLicense.driver.name.middleName}
                  ${driverLicense.driver.name.lastName}
                  ${driverLicense.driver.name.suffix}`}
              />
              <Attribute
                attr={i18n.getStr('components.Report.MotorVehicleReport.dob')}
                value={dateIfDate(driverLicense.driver.dob) || '-'}
              />
              <Attribute
                attr={i18n.getStr(
                  'components.Report.MotorVehicleReport.licenseNumber',
                )}
                value={driverLicense.number.toString()}
              />
              <Attribute
                attr={i18n.getStr(
                  'components.Report.MotorVehicleReport.licenseState',
                )}
                value={driverLicense.state}
              />
              <Attribute
                attr={i18n.getStr(
                  'components.Report.MotorVehicleReport.currentLicense',
                )}
                value={driverLicense.current.toString()}
              />
            </div>
          </div>
        ))}
      </Panel>
    );
  }

  renderLicenseReports(licenseReports) {
    return licenseReports.map((licenseReport, i) => (
      <React.Fragment key={`license-report-${i + 1}`}>
        {this.showLicenseReportData(licenseReport) && (
          <div>
            <div
              className='license-attributes mb-5'
              key={`license-attributes-${i + 1}`}
            >
              <h6>{`${licenseReport.number} (${licenseReport.state})`}</h6>
              <hr className='mb-2 mt-2' />
              {licenseReport.covidExtension && <CovidNotice />}
              <Attribute
                attr={i18n.getStr(
                  'components.Report.MotorVehicleReport.licenseStatus',
                )}
                value={licenseReport.status || '-'}
              />
              <Attribute
                attr={i18n.getStr(
                  'components.Report.MotorVehicleReport.licenseType',
                )}
                value={licenseReport.type || '-'}
              />
              <Attribute
                attr={i18n.getStr(
                  'components.Report.MotorVehicleReport.licenseClass',
                )}
                value={licenseReport.class || '-'}
              />
              <Attribute
                attr={i18n.getStr(
                  'components.Report.MotorVehicleReport.licenseExpirationDate',
                )}
                value={dateIfDate(licenseReport.expirationDate) || '-'}
              />
              <Attribute
                attr={i18n.getStr(
                  'components.Report.MotorVehicleReport.licenseIssuedDate',
                )}
                value={dateIfDate(licenseReport.issuedDate) || '-'}
              />
              <Attribute
                attr={i18n.getStr(
                  'components.Report.MotorVehicleReport.licenseFirstIssuedDate',
                )}
                value={dateIfDate(licenseReport.firstIssuedDate) || '-'}
              />
              {licenseReport.transcribedIssuedDate && (
                <AdditionalInfo
                  attributes={{
                    'components.Report.MotorVehicleReport.transcribedIssuedDate':
                      licenseReport.transcribedIssuedDate,
                  }}
                />
              )}
            </div>

            {licenseReport.medicalCertificates?.length && (
              <MedicalCertificates
                medicalCertificates={licenseReport.medicalCertificates}
              />
            )}
            {this.renderRestrictions(licenseReport.restrictions)}
            {this.renderEndorsements(licenseReport.endorsements)}
            {this.renderViolations(licenseReport.violations)}
            {this.renderAccidents(licenseReport.accidents)}
            {this.renderSuspensions(licenseReport.suspensions)}
            {this.renderMiscellaneousIncidents(
              licenseReport.miscellaneousIncidents,
            )}
          </div>
        )}
      </React.Fragment>
    ));
  }

  renderViolations(violations) {
    if (!violations.length) {
      return null;
    }

    return (
      <div className='mb-5'>
        <h6>
          <T value='components.Report.MotorVehicleReport.violations' />
        </h6>
        {violations.map((violation, i) => (
          <div className='mvr-violation mb-4' key={`violation-${i + 1}`}>
            <hr className='mb-2 mt-2' />
            <div className='d-flex'>
              <span className='text-uppercase'>{violation.description}</span>
              <span className='ml-auto'>
                {dateIfDate(violation.issuedDate)}
              </span>
            </div>
            <hr className='mb-2 mt-2' />
            <div className='mb-4 mt-4'>
              {this.renderIncidentAttributes(violation)}
            </div>
          </div>
        ))}
      </div>
    );
  }

  renderSuspensions(suspensions) {
    if (!(suspensions && suspensions.length)) {
      return null;
    }

    return (
      <div className='mb-5'>
        <h6>
          {i18n.getStr('components.Report.MotorVehicleReport.suspensions')}
        </h6>
        {suspensions.map((suspension, i) => (
          <div className='mvr-suspension mb-4' key={`suspension-${i + 1}`}>
            <hr className='mb-2 mt-2' />
            <div className='d-flex'>
              <span className='text-uppercase'>{suspension.description}</span>
              <span className='ml-auto'>
                {dateIfDate(suspension.startDate)}
              </span>
            </div>
            <hr className='mb-2 mt-2' />
            <div className='mb-4 mt-4'>
              {this.renderIncidentAttributes(suspension)}
            </div>
          </div>
        ))}
      </div>
    );
  }

  renderMiscellaneousIncidents(miscellaneous_incidents) {
    if (!(miscellaneous_incidents && miscellaneous_incidents.length)) {
      return null;
    }

    return (
      <div className='mb-5'>
        <h6>
          {i18n.getStr(
            'components.Report.MotorVehicleReport.miscellaneousIncidents',
          )}
        </h6>
        {miscellaneous_incidents.map((incident, i) => (
          <div
            className='mvr-miscellaneous-incident mb-4'
            key={`miscellaneous-${i + 1}`}
          >
            <hr className='mb-2 mt-2' />
            <div className='d-flex'>
              <span className='text-uppercase'>{incident.description}</span>
              <span className='ml-auto'>{dateIfDate(incident.date)}</span>
            </div>
            <hr className='mb-2 mt-2' />
            <div className='mb-4 mt-4'>
              {this.renderIncidentAttributes(incident)}
            </div>
          </div>
        ))}
      </div>
    );
  }

  renderAccidents(accidents) {
    if (!accidents.length) {
      return null;
    }

    return (
      <div className='mb-5'>
        <h6>{i18n.getStr('components.Report.MotorVehicleReport.accidents')}</h6>
        {accidents.map((accident, i) => (
          <div className='mvr-accident mb-4' key={`accident-${i + 1}`}>
            <hr className='mb-2 mt-2' />
            <div className='d-flex'>
              <span className='text-uppercase'>{accident.description}</span>
              <span className='ml-auto'>
                {dateIfDate(accident.accidentDate)}
              </span>
            </div>
            <hr className='mb-2 mt-2' />
            <div className='mb-4 mt-4'>
              {this.renderIncidentAttributes(accident)}
              <div className='row mb-1'>
                <div className='col-md-3 col-sm-4 text-very-muted'>
                  <T value='components.Report.MotorVehicleReport.note' />
                </div>
                <div className='col-md-9 col-sm-8 text-very-muted font-italic'>
                  {accident.note}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  renderRestrictions(restrictions) {
    if (!restrictions.length) {
      return null;
    }

    return (
      <div className='mb-5'>
        <h6>
          <T value='components.Report.MotorVehicleReport.restrictions' />
        </h6>
        <hr className='mb-2 mt-2' />
        <ul className='list-unstyled'>
          {restrictions.map((restriction, i) => (
            <li
              className='mvr-restriction text-uppercase'
              key={`restriction-${i + 1}`}
            >
              {restriction}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  renderEndorsements(endorsements) {
    if (!endorsements.length) {
      return null;
    }

    return (
      <div className='mb-5'>
        <h6>
          {i18n.getStr('components.Report.MotorVehicleReport.endorsements')}
        </h6>
        <hr className='mb-2 mt-2' />
        <ul className='list-unstyled'>
          {endorsements.map((endorsement, i) => (
            <li
              className='mvr-endorsement text-uppercase'
              key={`endorsement-${i + 1}`}
            >
              {endorsement.dmvDescription}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  render() {
    const { canada, search } = this.props;
    if (!search || search.status === 'canceled') {
      return null;
    }

    return (
      <Panel
        title={i18n.getStr('components.Report.MotorVehicleReport.title')}
        status={search.status}
      >
        {(search.notFound ||
          search.experienceFailed ||
          search.unsupportedProvince) && (
          <div className='mb-3'>
            {search.notFound && search.cdlisRecord === null && (
              <span className='cdlis-record-not-found'>
                {i18n.getStr(
                  'components.Report.MotorVehicleReport.cdlisRecordsNotFound',
                )}
              </span>
            )}
            {search.unsupportedProvince && (
              <span key='idh' className='badge badge-warning text-capitalize'>
                <T
                  value='components.Report.MotorVehicleReport.unsupportedProvince'
                  province={search.province}
                />
              </span>
            )}
          </div>
        )}

        {/* If cdlis_record is defined, assume DotMvr resource type */}
        {!canada &&
          !!search.cdlisRecord &&
          this.renderCdlisRecord(search.cdlisRecord)}

        {/* If licenseReports is defined, assume MultiMvr resource type */}
        {!canada &&
          !!search.licenseReports &&
          this.renderLicenseReports(search.licenseReports)}

        {!canada && !search.licenseReports && (
          <div className='mb-5'>
            <Attribute
              attr={i18n.getStr(
                'components.Report.MotorVehicleReport.fullName',
              )}
              value={search.fullName || '-'}
            />
            <Attribute
              attr={i18n.getStr(
                'components.Report.MotorVehicleReport.currentLicenseStatus',
              )}
              value={search.licenseStatus || '-'}
            />
            <Attribute
              attr={i18n.getStr(
                'components.Report.MotorVehicleReport.currentLicenseType',
              )}
              value={search.licenseType || '-'}
            />
            <Attribute
              attr={i18n.getStr(
                'components.Report.MotorVehicleReport.currentLicenseClass',
              )}
              value={search.licenseClass || '-'}
            />
            <Attribute
              attr={i18n.getStr(
                'components.Report.MotorVehicleReport.currentLicenseExpirationDate',
              )}
              value={dateIfDate(search.expirationDate) || '-'}
            />
            <Attribute
              attr={i18n.getStr(
                'components.Report.MotorVehicleReport.currentLicenseIssuedDate',
              )}
              value={dateIfDate(search.issuedDate) || '-'}
            />
            <Attribute
              attr={i18n.getStr(
                'components.Report.MotorVehicleReport.currentLicenseFirstIssuedDate',
              )}
              value={dateIfDate(search.firstIssuedDate) || '-'}
            />
          </div>
        )}

        {canada && (
          <div className='mb-5'>
            <Attribute
              attr={i18n.getStr(
                'components.Report.MotorVehicleReport.fullName',
              )}
              value={search.fullName || '-'}
            />
            <Attribute
              attr={i18n.getStr(
                'components.Report.MotorVehicleReport.currentDriverStatus',
              )}
              value={search.driverStatus || '-'}
            />
            <Attribute
              attr={i18n.getStr(
                'components.Report.MotorVehicleReport.currentDriverClass',
              )}
              value={search.driverClass || '-'}
            />
            <Attribute
              attr={i18n.getStr(
                'components.Report.MotorVehicleReport.currentLicenseNumber',
              )}
              value={search.licenseNumber || '-'}
            />
            <Attribute
              attr={i18n.getStr(
                'components.Report.MotorVehicleReport.currentLicenseProvince',
              )}
              value={search.province || '-'}
            />
            <Attribute
              attr={i18n.getStr(
                'components.Report.MotorVehicleReport.currentLicenseType',
              )}
              value={search.licenseType || '-'}
            />
            <Attribute
              attr={i18n.getStr(
                'components.Report.MotorVehicleReport.currentLicenseExpiryDate',
              )}
              value={dateIfDate(search.expiryDate) || '-'}
            />
            <Attribute
              attr={i18n.getStr(
                'components.Report.MotorVehicleReport.currentLicenseOriginalDate',
              )}
              value={dateIfDate(search.originalDate) || '-'}
            />
            <Attribute
              attr={i18n.getStr(
                'components.Report.MotorVehicleReport.currentLicensePoints',
              )}
              value={search.points || '-'}
            />
          </div>
        )}

        {(canada || !search.licenseReports) &&
          this.renderEndorsements(search.endorsements)}
        {(canada || !search.licenseReports) &&
          this.renderViolations(search.violations)}
        {(canada || !search.licenseReports) &&
          this.renderSuspensions(search.suspensions)}
        {(canada || !search.licenseReports) &&
          this.renderAccidents(search.accidents)}
        {(canada || !search.licenseReports) &&
          this.renderRestrictions(search.restrictions)}
        {(canada || !search.licenseReports) &&
          this.renderMiscellaneousIncidents(search.miscellaneousIncidents)}
      </Panel>
    );
  }
}

MotorVehicleReport.propTypes = {
  canada: PropTypes.bool,
  search: PropTypes.object,
};

MotorVehicleReport.defaultProps = {
  search: null,
  canada: false,
};

export default MotorVehicleReport;
