import PropTypes from 'prop-types';
import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { camelize } from 'humps';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import Status from '../Status';
import formattedInputValue from './ProLicenseVerificationHelpers';

const CONSIDER = 'consider';

const toStartCase = str => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/[_-]+/g, ' ')
    .replace(/\b\w/g, char => char.toUpperCase());
};

class ProLicenseVerificationCertificationResults extends React.Component {
  formattedSubResultInputValue(subResult, input) {
    // The camelize call is needed here because candidate portal always call camelizeKeys on serialized report json
    // For this matching to work, the result.input key needs to match subResult.name
    return input
      ? input[subResult.name] || input[camelize(subResult.name)]
      : '-';
  }

  formatConsiderSubChecks(result) {
    const subChecks = result.subChecks
      .filter(subCheck => subCheck.status.toLowerCase() === CONSIDER)
      .map(
        subCheck =>
          i18n.getStr(
            `components.Report.ProLicenseVerification.subChecks.${subCheck.name}`,
          ) || '',
      )
      .join(',');
    return subChecks ? `(${subChecks})` : '';
  }

  renderCertificationResult(certificationsEntry) {
    const { result, input } = certificationsEntry;
    return (
      <div className='mb-4' key={`div-${result.certificationIssuer}`}>
        {this.renderCertificationResultHeader(result)}
        {this.renderCertificationResultLicenseStatusExpiration(result)}
        <br />
        <div className='table-responsive'>
          <table className='table table-sm table-bottom-bordered'>
            <tbody>
              <tr>
                <td />
                <td>
                  <b>
                    <T value='components.Report.ProLicenseVerification.candidateInput' />
                  </b>
                </td>
                <td>
                  <b>
                    <T value='components.Report.ProLicenseVerification.matchedResult' />
                  </b>
                </td>
              </tr>
              {this.renderCertificationResultOrderDetail(result, input)}
              {this.renderCertificationSubResults(result, input)}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderCertificationResultHeader(result) {
    return (
      <div>
        <div className='d-flex'>
          <div>
            <b>{result.certificationIssuer}</b>
          </div>
          <div className='ml-auto'>
            <Status status={result.active ? 'clear' : 'consider'} />
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderCertificationResultLicenseStatusExpiration(result) {
    const icon = result.active ? 'Checkmark' : 'WarningFilled';

    return (
      <div>
        <div className='row mb-1'>
          <div className='col-md-2 col-sm-4'>
            <T value='components.Report.ProLicenseVerification.licenseStatus' />
            :
          </div>
          <div className='col-md-10 col-sm-8'>
            <M.Icon icon={icon} />{' '}
            <b>
              <T
                value={`components.Report.ProLicenseVerification.${
                  result.active ? 'verified' : 'unverified'
                }`}
              />
            </b>
            {` ${this.formatConsiderSubChecks(result)}`}
          </div>
        </div>
        <div className='row mb-1'>
          <div className='col-md-2 col-sm-4'>
            <T value='components.Report.ProLicenseVerification.expirationDate' />
            :
          </div>
          <div className='col-md-10 col-sm-8'>
            {result.certificationExpiration || '-'}
          </div>
        </div>
      </div>
    );
  }

  renderCertificationResultOrderDetail(result, input) {
    const { candidateName } = this.props;

    const certificationOrderDetails = [
      'certificationIssuedTo',
      'certificationIssuerRegion',
      'certificationIssuer',
      'certificationName',
    ];

    return certificationOrderDetails.map(key => {
      return (
        <tr key={`${key}`}>
          <td>
            <span className='text-muted'>
              <T
                value={`components.Report.ProLicenseVerification.orderDetails.${key}`}
              />
            </span>
          </td>
          <td>
            <span>{formattedInputValue(key, input, candidateName)}</span>
          </td>
          <td>
            <span>{result[key] || '-'}</span>
          </td>
        </tr>
      );
    });
  }

  renderCertificationSubResults(result, input) {
    const subResults = result.subResults || [];

    return subResults.map(subResult => {
      return (
        <tr key={`${subResult.name}`}>
          <td>
            <span className='text-muted'>{toStartCase(subResult.name)}</span>
          </td>
          <td>
            <span>{this.formattedSubResultInputValue(subResult, input)}</span>
          </td>
          <td>
            <span>{subResult.value}</span>
          </td>
        </tr>
      );
    });
  }

  render() {
    const { certifications } = this.props;

    return certifications.map(certificationsEntry => {
      return this.renderCertificationResult(certificationsEntry);
    });
  }
}

ProLicenseVerificationCertificationResults.propTypes = {
  certifications: PropTypes.array,
  candidateName: PropTypes.string,
};

ProLicenseVerificationCertificationResults.defaultProps = {
  certifications: [],
  candidateName: '',
};

export default i18n.renderTranslation()(
  ProLicenseVerificationCertificationResults,
);
