import React from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

interface GreetingProps {
  company?: string;
  companyLogoUri?: string;
  firstName?: string;
}

export const Greeting: React.FC<GreetingProps> = ({
  company = null,
  companyLogoUri = '',
  firstName = null,
}) => {
  return (
    <M.Container
      style={{
        border: 'none',
        background: colors.uiGrey50,
        flexDirection: 'row',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      <h4>{i18n.getStr('components.InstantExceptions.greeting', firstName)}</h4>
      <img alt={`${company} logo`} src={companyLogoUri} />
    </M.Container>
  );
};
