import React from 'react';
import { useDispatch } from 'react-redux';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import './CandidateLandingPage.scss';
import { ReportSummary } from 'api/candidates/types/Report';
import Faq from 'components/Faq';
import ReportList from './ReportList';
import { changeReportIndex, trackAnalyticsEvent } from '../../../actions';
import { ANALYTICS_EVENTS } from '../../../lib/analytics';
import I18nLink from '../../I18nLink/I18nLink';
import { useAda } from './useAdaChatbot';

export interface CandidateLandingPageProps {
  firstName?: string;
  reports: ReportSummary[];
}

const CandidateLandingPage: React.FC<CandidateLandingPageProps> = ({
  firstName,
  reports = [],
}) => {
  const orderedReports: ReportSummary[] = reports.map((report, i) => ({
    ...report,
    reportOrder: i + 1,
  }));
  useAda(window?.location?.href || '');
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(changeReportIndex(0));
    dispatch(trackAnalyticsEvent(ANALYTICS_EVENTS.LANDING_PAGE_VIEWED));
  }, [dispatch]);

  return (
    <div className='candidate-landing-page'>
      <M.Grid fullWidth as='header' className='candidate-header'>
        <M.Container type='transparent' className='candidate-header-container'>
          <M.Container.Row>
            <M.Container.Col>
              <h2 id='greeting'>
                {firstName
                  ? i18n.getStr(
                      'components.CandidateLandingPage.greeting',
                      firstName,
                    )
                  : i18n.getStr('components.CandidateLandingPage.welcome')}
              </h2>
              <p id='manageYourReportsMessage'>
                {i18n.getStr(
                  'components.CandidateLandingPage.manageYourReportsMessage',
                )}
              </p>
            </M.Container.Col>
          </M.Container.Row>
        </M.Container>
      </M.Grid>
      <M.Grid fullWidth className='candidate-body'>
        <M.Container as='main' type='transparent' className='candidate-main'>
          <ReportList reports={orderedReports} />
        </M.Container>
        <M.Container
          type='transparent'
          as='footer'
          className='candidate-footer'
        >
          <M.GridCol className='candidate-footer-container'>
            <Faq translationKey='components.CandidateLandingPage.Faq' />
            <I18nLink
              translationKey={[
                `translationKey.helpCenterMessage`,
                'components.Faq.helpCenterMessage',
              ]}
              className='faq-help-center-message'
              id='faq-help-center-message'
              onLinkClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                dispatch(
                  trackAnalyticsEvent(ANALYTICS_EVENTS.HELP_CENTER_VIEWED),
                )
              }
            />
          </M.GridCol>
        </M.Container>
      </M.Grid>
    </div>
  );
};

export default i18n.renderTranslation()(
  CandidateLandingPage,
) as React.FC<CandidateLandingPageProps>;
